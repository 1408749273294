@import 'styles/_mixins.scss';

.container {
  position: relative;
  min-height: 110px;
}

.content_container {
  opacity: 0.4;
  height: 100%;
}

.spinner {
  position: absolute;
  z-index: 1000;
  left: 50%;
  margin-left: -2.2rem;
  top: max(50%, 50%);
  margin-top: -0.8rem;
  width: 3.6rem;
  height: 3.6rem;
  border-width: 0.5em;
  color: $blue-dark;
}

.small {
  position: absolute;
  z-index: 1000;
  left: 36%;
  top: min(40%, 100%);
  width: 1.75rem;
  height: 1.75rem;
  border-width: 0.25em;
  color: $blue-dark;
}

.height {
  height: 100%;
}
