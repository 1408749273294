@import 'styles/_mixins.scss';

.container {
  padding: 75px 0 100px 0;

  @include mobile {
    padding: 40px 0 20px;
  }
}

.img__vector {
  position: absolute;
  z-index: 0;
  top: -80px;
  left: 0;
}

.box {
  margin: 75px 0 0;

  @include mobile {
    margin: 75px 0 20px;
  }
}

.box,
.info__box {
  position: relative;
  z-index: 1;
  background: #ffffff;
  box-shadow: 0px 5px 15px 3px rgba(51, 51, 64, 0.15);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.box {
  @include mobile {
    padding: 20px;
  }
}

.info__box {
  margin-top: 32px;
  padding: 20px;
}

.box__title {
  margin: 40px 35px 30px 45px;
  align-self: left;
  width: 227px;

  @include mobile {
    margin: 40px 0 30px;
    width: inherit;
  }
}

.box__image {
  @include mobile {
    width: inherit;
  }
}

.box__text {
  font-weight: normal;
  font-size: 14px;
  color: #595970;
  margin: 0 35px 30px 45px;
  text-align: justify;

  @include mobile {
    margin: 40px 5px 30px;
    width: inherit;
  }
}

.footer__buttons {
  margin: 0 35px 35px 35px;
  align-self: left;

  @include mobile {
    margin: 0 5px 30px;
  }
}

.button {
  margin: 0 10px;
}

.info__box__title {
  font-weight: bold;
  font-size: 22px;
  color: #333340;
}

.info__box__text {
  font-weight: 600;
  font-size: 12px;
  color: #88889d;
}

.right__section__title {
  font-weight: 600;
  font-size: 30px;
  color: #333340;
}

.container__info__box__col {
  padding: 10px 0 0 20px;
  white-space: nowrap;

  @include mobile {
    padding: 10px 0;
  }
}

.right__container__tools {
  padding: 75px 0 20px 60px;
  margin-right: 25px;

  @include mobile {
    margin: 75px 5px 0 5px;
    padding: 0;
  }
}

.right__container__item {
  margin: 30px 80px 0 0;
  flex-wrap: nowrap;

  @include mobile {
    margin-left: 15px;
  }
}

.right__section__container__title {
  padding-top: 5px;
  font-weight: 600;
  font-size: 18px;
  color: #333340;
}

.right__section__container__text {
  font-weight: normal;
  font-size: 12px;
  color: #595970;
}

.img__tools {
  display: flex;
  margin-right: 20px;
  align-self: flex-start;
  padding-top: 10px;
  object-position: top;
}
