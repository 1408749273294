@import 'styles/_mixins.scss';

.images {
  position: relative;
  overflow: hidden;
  border-radius: 12px 12px 0 0;
}

.profile_background {
  width: 100%;
  height: auto;
}

.profile_image_logo {
  position: absolute;
  height: 80px;
  width: 80px;
  top: 50%;
  margin-top: -40px;
  left: 25px;
  border-radius: 5px;
}
