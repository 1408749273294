@import 'styles/_mixins.scss';

.button {
  margin-bottom: 70px;
  display: flex;
  justify-content: center;
  align-items: center;

  @include mobile {
    margin: 0 20px 70px;
  }
}

.list__company {
  padding: 30px 160px;
  box-shadow: 0px 5px 15px 3px rgba(51, 51, 64, 0.15);
  border-radius: 20px;
  display: flex;
  align-items: center;

  @include mobile {
    padding: 30px 20px;
  }
}
