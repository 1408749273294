@import 'styles/_mixins.scss';

.container {
  margin: 20px 0 50px;
}

.title {
  @include h1;
  color: $dark;
  text-align: center;
  margin: 10px 10% 60px;

  @include mobile {
    margin: 0 0 60px;
  }
}

.white {
  color: $shade;
}

.black {
  color: $dark;
}

.item__title {
  @include h4;
  color: $dark;
  margin-bottom: 15px;
}

.item__text {
  @include p;
  color: #595970;
  margin-bottom: 40px;

  @include mobile {
    margin-bottom: 20px;
  }
}

.img__item__col {
  background: #eaeaf5;
  border-radius: 10px;
  width: 100%;
}

.img__item {
  margin-bottom: 20px;
}

.item__footer {
  width: 100%;
  margin-bottom: 120px;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;

  @include mobile {
    margin-bottom: 50px;
  }
}
