@import 'styles/_mixins.scss';

.duns_registered__container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include mobile {
    flex-direction: column-reverse;
  }
}

.duns_registered__img_container {
  position: relative;
  max-width: 590px;
}

.duns_registered__img {
  width: 100%;
  z-index: 100;
  position: relative;
}

.duns_registered__content_container {
  max-width: 590px;
  z-index: 60;
}

.duns_registered__title {
  font-size: 2.1rem;
  color: $dark;
  font-weight: 600;
  vertical-align: middle;
  margin-bottom: 15px;
}

.duns_registered__content {
  line-height: 28px;
  max-width: 500px;
  margin-bottom: 30px;
}

.sidebar_button__order_report {
  @include mobile {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
  }
}

.vector__duns_registered__img {
  position: absolute;
  z-index: 50;
  top: -60px;
}

.vector {
  position: absolute;
}

.vector_israel {
  position: relative;
}
