@import 'styles/_mixins.scss';

.title__industry {
  font-weight: 600;
  font-size: 30px;
  margin: 70px 0 45px;

  @include mobile {
    margin-top: 40px;
    margin-bottom: 24px;
  }
}

.browse__industries {
  font-weight: 600;
  font-size: 16px;
}

.browse__button {
  padding: 32px 0 40px;
}

.container {
  flex-direction: column;
}
