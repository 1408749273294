@import 'styles/_mixins.scss';

.searchBarContainer {
  position: relative;
}

.searchIcon {
  background-color: transparent;
  border: none;
  color: grey;
  &:hover,
  &:active,
  &:focus {
    color: $shade-50;
    outline: none;
  }
}

.suggestion {
  vertical-align: middle;
}

.regular {
  font-size: 1em;
  color: $dark;
}

.highlight {
  color: $dark;
  font-weight: bold;
  font-size: 1em;
}

.company_icon {
  margin-right: 0.8em;
  font-size: 0.9em;
  color: $shade-40;
}

.search_bar__actions_container {
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding-right: 15px;
}

.dropdown {
  line-height: 26px;
  color: $dark;
  margin-left: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 120px;
  display: inline-block;

  & svg {
    position: absolute;
    left: 100px;
    top: 12px;
  }
}

.vl {
  position: absolute;
  border-left: 1px solid $border;
  height: 22px;
}
