@import 'styles/_mixins.scss';

.header_container {
  background-color: #005172;
}

.extra_hight {
  padding-bottom: 210px;
}

.title {
  @include h1;
  color: $shade;
}

.center {
  align-items: center;
  padding: 60px 10% 40px;
  justify-content: center;
  text-align: center;
}

.left {
  align-items: left;
  padding: 60px 0px 40px 8px;
  justify-content: left;
  text-align: left;

  @include mobile {
    padding: 60px 0px 40px 16px;
  }

  @include tablet {
    padding: 60px 0px 40px 16px;
  }
}

.button {
  margin: 0px 0px 0px 15px;
  align-self: center;

  @include mobile {
    margin: 0px 0px 0px !important;
    width: 100%;
    border-radius: 25px;
  }
}
