@import 'styles/_mixins.scss';

.input {
  width: 100%;
  height: 32px;
  border: 1px solid $border;

  box-sizing: border-box;
  border-radius: 50px;
  padding: 9px 44px 10px 20px;

  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: $shade;
  background-color: #005172;
}

.input:hover {
  box-shadow: 0 0 0 0.075rem rgba($color: $backgroundBlue, $alpha: 1), 0 0 0 0.2rem rgba($color: $shade, $alpha: 0.5);
  outline: none;
}
.inputFocused {
  color: $dark;
  background-color: $shade;
  box-shadow: 0 0 0 0.075rem rgba($color: $backgroundBlue, $alpha: 1), 0 0 0 0.2rem rgba($color: $shade, $alpha: 0.5);
  outline: none;
}

.suggestionsContainer {
  width: 100%;
}

.containerOpen .suggestionsContainer {
  display: block;
  position: absolute;
  margin-top: 8px;
  background-color: #fff;
  font-weight: 300;
  font-size: 14px;
  border-radius: 8px;
  overflow: hidden;
  z-index: 300;
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.12);
  width: 200%;
}

.suggestionsList {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.suggestion {
  cursor: pointer;
  padding: 8px 20px;
}

.suggestionHighlighted {
  background-color: $shade-10;
}

.suggestionFirst {
  border-top: 1px solid $shade-10;
}

.react-autosuggest__suggestion--focused {
  background-color: $blue-80;
  color: $shade;
}

.container {
  width: 100%;
}

.input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-weight: 600;
  color: $shade-50;
  opacity: 1; /* Firefox */
}

.input:-ms-input-placeholder  {
  font-weight: 600;
  color: $shade-50;
}

.input::-ms-input-placeholder  {
  font-weight: 600;
  color: $shade-50;
}
