@import 'styles/_mixins.scss';

.home__search_container {
  background-color: $backgroundBlue;
  align-content: center;
  padding-top: 2rem;

  @include mobile {
    flex-direction: column-reverse;
  }
}

.home__searchbar_subtitle {
  margin: 16px 0 32px 0;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.8;
  color: #ffffff;
  opacity: 0.75;
}

.home__powered {
  margin-top: 1rem;
  align-items: center;
  text-align: right;
  padding-bottom: 40px;
  display: flex;
  justify-content: flex-end;
}

.home__powered_text {
  color: rgba(255, 255, 255, 0.75);
  display: flex;
  flex-shrink: 0;
  padding-right: 0.5rem;
  vertical-align: middle;
  font-size: 0.86rem;
}

.home__powered img {
  width: auto;
  height: 24px;
  vertical-align: middle;
}

.home__search_img {
  padding-left: 80px;
  margin-bottom: -100px;
  @include mobile {
    display: none;
  }
}

.home__search_img img {
  min-width: 430px;
  width: 80%;
  height: auto;
}

.button__text {
  display: flex;

  @include mobile {
    margin: 0 20px;
    align-items: center;
  }
}

.button__text__bold {
  font-weight: 600;
  padding: 0 5px;

  @include mobile {
    margin: 0 0 0 20px;
  }
}
