@import 'styles/_mixins.scss';

.background__vector {
  display: flex;
  padding: 50px 0 20px 0;
  position: relative;
}

.img__vector__container {
  position: absolute;
  margin: 20px;
  left: 0;
  overflow: hidden;
}

.img__vector {
  position: relative;
}

.box {
  background: #ffffff;
  box-shadow: 0px 5px 15px 3px rgba(51, 51, 64, 0.15);
  border-radius: 20px;
  z-index: 100;
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  padding: 0 10%;

  @include mobile {
    margin-right: 5px;
    margin-left: 5px;
  }
}

.box__title {
  font-weight: 600;
  font-size: 30px;
  color: #333340;
  margin: 40px 0 15px 0;
  align-self: center;
}

.box__text {
  font-weight: normal;
  font-size: 16px;
  color: #595970;
  margin: 0 0 35px 0;
  align-self: center;
}

.footer__buttons {
  margin: 0 0 55px 0;
  align-self: center;

  @include mobile {
    display: flex;
    flex-direction: column;
    align-self: none;
  }
}

.button {
  margin: 0 10px 15px 0;
}
