.container {
  display: inline-flex;
  flex-grow: 1;
}

.container__border {
  border: 1px solid #ccccdc;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 25px;
  cursor: pointer;
}

.industry__name {
  font-size: 16px;
  flex-grow: 1;
  margin-right: 16px;
}

.industry__icon {
  color: #88889d;
}
