/****** Colors ******/

$color--brand-primary: #005172;
$color--brand-light: #3095b4;
$color--brand-extra-light: #3fb2ff;

$background: #f6f7fa;
$border: #e6e9f0;
$backgroundBlue: #005172;

$shade: #fff;
$shade-0: #f3f3fb;
$shade-10: #eaeaf5;
$shade-20: #dadae9;
$shade-30: #ccccdc;
$shade-40: #b7b7ca;
$shade-50: #9d9db1;
$shade-60: #88889d;
$shade-70: #707086;
$shade-80: #595970;
$shade-90: #414152;
$shade-100: #333340;

$blue-20: #aacbfb;
$blue-40: #83b2f6;
$blue-60: #619cf3;
$blue-80: #3d88f5;
$blue-100: #136bed;

$blue: #4b86ff;
$blue-dark: #1a5be0;

$green: #59cd90;
$green-dark: #3cb073;

$red: #f08864;
$red-dark: #d6704d;

$orange: #fac05e;
$orange-dark: #dda342;

$dark: $shade-100;
$mediumDark: $shade-80;

$color-label: $shade-60;
$color-link: $blue;

$color-primaryExtraLight: #9abbff;
$color-primaryLight: #71a0ff;
$color-primary: #4b86ff;
$color-primaryMedium: #3473f5;
$color-primaryDark: #1a5be0;

$nav-bar-pill: #e9effd;
$nav-bar-pill-text: #677394;

/****** Brand color ******/
$facebook: #395799;
$twitter: #00bbd3;
$linkedin: #2095f2;
$mail: #555;

/****** Badges ******/
@mixin dunsRegistered {
  background-color: #d0e2eb;
  color: #0c5172;
}

@mixin dunsCredibility {
  background-color: #00bbd3;
  color: $shade;
}

@mixin dunsCredibilityPremium {
  background-color: #a58e4a;
  color: $shade;
}

@mixin duns100 {
  background-color: $dark;
  color: $shade;
}

@mixin defaultBadge {
  background-color: #6c757d;
  color: $shade;
}

/****** Fonts ******/

$appFont: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
$bodyFont: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;

/****** Rando ******/

$border-radius: 4px;
$boxShadow: 0 1px 3px 0 rgba(51, 51, 64, 0.08), 0 1px 1px 0 rgba(51, 51, 64, 0.08);

$border-radius-card: 0.75rem;
$boxShadow-card: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
$background-card: #ffffff;

/****** Spacing ******/
$card-spacing: 24px;

/****** Mixins ******/

@mixin popover {
  border: 1px solid $border;
  box-shadow: 0px 3px 6px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

@mixin card {
  border-radius: $border-radius-card;
  box-shadow: $boxShadow-card;
  background: $background-card;
}

@mixin sidebar-title {
  font-weight: bold;
  font-size: 1.2rem;
  color: $shade-100;
  padding: 0 0 15px 0;
  margin-bottom: 0;
}

/****** Text ******/

// Headings (used for app interfaces)

@mixin h1 {
  color: $dark;
  font-weight: 600;
  font-size: 40px;
  line-height: 1.3;
  margin: 0;
}

@mixin h2 {
  color: $dark;
  font-weight: 600;
  font-size: 30px;
  line-height: 1.3;
  margin: 0;
}

@mixin h3 {
  color: $dark;
  font-weight: 600;
  font-size: 26px;
  line-height: 1.3;
  margin: 0;
}

@mixin h4 {
  color: $dark;
  font-weight: normal;
  font-size: 22px;
  line-height: 1.3;
  margin: 0;
}

@mixin h5 {
  color: $dark;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.3;
  margin: 0;
}

// Headings (used for app interfaces)

@mixin h1-app {
  color: $dark;
  font-weight: 500;
  font-size: 26px;
  line-height: 1.3;
  margin: 0;
}

@mixin h2-app {
  color: $dark;
  font-weight: 500;
  font-size: 22px;
  line-height: 1.3;
  margin: 0;
}

@mixin h3-app {
  color: $dark;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.3;
  margin: 0;
}

@mixin h4-app {
  color: $dark;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.3;
  margin: 0;
}

@mixin h5-app {
  color: $dark;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.3;
  margin: 0;
}

// Paragraphs

@mixin p {
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5;
}

@mixin p-s {
  font-weight: normal;
  font-size: 12px;
  line-height: 1.5;
}

@mixin p-l {
  font-weight: normal;
  font-size: 16px;
  line-height: 1.5;
}

@mixin p-xl {
  font-weight: normal;
  font-size: 18px;
  line-height: 1.5;
}

/****** Responsive ******/

@mixin tablet {
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: 767px) {
    @content;
  }
}
