@import 'styles/_mixins.scss';

.Footer__lists {
  padding-top: 50px;
}

.Footer__view_all_link {
  font-weight: bold;
  line-height: 1.5;
  padding: 0;
  text-align: left;
  color: $shade;
}
