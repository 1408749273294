@import 'styles/_mixins.scss';

.title {
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: #ffffff;
  margin-bottom: 25px;
}

.item {
  color: #ffffff;
  font-weight: normal;
  line-height: 18px;
  text-align: left;
  margin: 0 0 15px;
  display: flex;
  padding: 0;
}

.footer {
  font-weight: bold;
  line-height: 18px;
  color: #ffffff;
}
