@import 'styles/_mixins.scss';

.title__company {
  font-weight: 600;
  font-size: 30px;
  margin: 70px 0 45px initial;

  @include tablet {
    margin: 30px 0 45px initial;
  }

  @include mobile {
    margin: 30px 0 45px initial;
  }
}

.browse__companies {
  font-weight: 600;
  font-size: 16px;
}

.browse__button {
  padding: 32px 0 0;

  @include tablet {
    padding: 32px 0;
  }

  @include mobile {
    padding: 32px 0;
  }
}

.container {
  flex-direction: column;
}
